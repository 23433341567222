<template>
  <div class="layout-main">
    <main-header></main-header>

    <div class="container">
      <div class="aside">
        <aside-menu></aside-menu>
      </div>
      <div class="main">
        <el-scrollbar style="height: 100%;">
          <el-breadcrumb separator="/" class="breadcrumb">

            <el-breadcrumb-item v-for="(item,index) in metas"
                                :key="index"
                                v-if="item.meta && item.meta.title"><span class="text-color"> {{item.meta.title}}</span>
            </el-breadcrumb-item>
          </el-breadcrumb>
          <div class="main-container">

            <router-view class="main-bg"></router-view>

          </div>

          <div class="slog-container">
            <p class="slog">物联助力咖啡业务更高效</p>
            <p>云咖信息系统©为您更好使用物联云服务</p>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
  import AsideMenu from '@/components/Menu/index.vue'
  // import MainHeader from '@/components/Header/company.vue'
  import MainHeader from '@/components/Header/index.vue'
  import {employeeModules} from "@/api/account/employee";
  import {mapGetters, mapActions} from 'vuex'
  import {removeToken, getToken} from '@/util/auth'

  export default {
    name: '',
    components: {
      AsideMenu,
      MainHeader,
    },
    computed: {
      ...mapGetters(['accountPage']),
    },

    mounted: function () {
      let vue = this
      let token = getToken()
      if (token) {
        employeeModules().then(res => {
          if (res.code == 0) {
            res.data = res.data || []
            if (res.data.length === 0 || res.data[0].modules === null) {
              vue.$message.warning('请联系管理员开通权限')
              removeToken()
              vue.$router.replace({name: 'login'})
              return
            }
            let showMenus = res.data
            let menus = [
              // {
              //   uuid: 'a0',
              //   name: '营销策划',
              //   icon: 'iconfont iconyingxiao',
              //   modules: [
              //     {uuid: 'a', name: '一点通', icon: "iconfont iconyunyingzhongxin1", webRouterName: "operation.ecode"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     {uuid: 'a', name: '周边商城', icon: "iconfont iconyunyingzhongxin1", webRouterName: "marketing.shop"},
              //     // {uuid:'a', name: '取货码', icon: "iconfont iconyunyingzhongxin1", webRouterName: "operation.takeCode"},
              //     // {uuid:'a', name: '组合卡', icon: "iconfont iconyunyingzhongxin1", webRouterName: "operation.realCard"},
              //     // {uuid:'a', name: '用户列表', icon: "iconfont iconyunyingzhongxin1", webRouterName: "operation.marketing"},
              //     // {uuid:'a', name: '发货订单', icon: "iconfont iconyunyingzhongxin1", webRouterName: "operation.shop"},
              //   ]
              // }
            ]

            menus.forEach(menu => {
              showMenus.push(menu)
            })
            vue.setEnterpriseMenu({
              navMenus: showMenus,
              routerName: this.$route.name,
            })
          }
        })
      }
    },
    watch: {
      "$route": function (val) {
        this.getBreadcrumb()
      }
    },
    data: function () {
      return {
        collapse: false,
        metas: [] // 路由集合
      }
    },
    methods: {
      ...mapActions(['setEnterpriseMenu', `setAccountPageByRouterName`]),
      isHome(route) {
        return route.name === "home";
      },
      getBreadcrumb() {
        let matched = this.$route.matched;
        //如果不是首页
        if (!this.isHome(matched[0])) {
          matched = [{path: "/home", meta: {title: "首页"}}].concat(matched);
        }
        this.metas = matched;
      }
    },
    created() {
      this.getBreadcrumb()
    }
  }
</script>

<style lang="scss" scoped>
  body {
    @include bg_color($background-color-light);
    @include set_font_color($--font-color, $--font-color-dark);
  }


  .layout-main {
    width: 100%;
    height: 100%;

    .is-vertical {
      width: 2px !important;
    }

    .container {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      top: $_header_height;
      @include set_bg_color($background-color-light-2, $background-color-dark-1);


      .aside {
        @include set_bg_color($background-color-light, $background-color-dark);
        left: 0;
        top: $_header_height + 1px;
        bottom: 0;
        position: fixed;
        z-index: 99;
        @include set_shadow_color(#eeeeee, #fff);


      }

      .main {
        margin-right: $--aside-question;
        height: 100%;
        overflow-y: hidden;
        margin-left: $--aside-min-width;
        @include set_bg_color($background-color-light, $background-color-dark);

        .breadcrumb {
          padding-top: $margin * 1.2;
          padding-bottom: $margin * 1;
          padding-left: $margin * 1.5;
          font-size: 16px;

          @include set_shadow_color(#cecece, #fff);

        }

        .main-container {
          //  margin: $margin;
          min-height: 618px;

          .break {
            margin-bottom: $padding;

            .explain {
              font-size: 12px;
              display: inline-block;
              margin-left: $padding-8;
              @include set_font_color($--font-03-color, $--font-color-dark);
            }
          }
        }

        .main-bg {
          padding: $padding-contain;
          height: 100%;

          .break {
            margin-bottom: 0;
          }
        }
      }
    }

    .break {
      color: $_theme_color;

      i {
        vertical-align: middle;
        margin-right: 4px;
        width: 24px;
        text-align: center;
        font-size: 16px;
      }

    }

    .slog-container {
      margin-bottom: $margin;
      color: #999;
      text-align: center;
      font-size: 12px;

      .slog {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

</style>
